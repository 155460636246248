import { ListObject } from "./list-object.interface";

export interface PrequalQuestion {
  ProgramQualificationId: number,
  ProgramPrequalificationQuestionId: number,
  SortOrder: number,
  Name: string,
  Description: string,
  Required: ListObject | null,
  MostFavorableMedianIncomeAmount: string
}