import { ListObject } from "src/app/shared/interfaces";

export interface EligibleExpense {
  Category: ListObject | null,
  ExpenseType: ListObject | null,
  Provider: ListObject | null,
  IsOtherProvider: boolean,
  OtherProviderName: string,
  ProviderPhoneNumber: string,
  AccountNumber: string,
  PaymentDueDate: ListObject | null,
  Payment: number,
  PaymentPastDue: number,
  WhoPays: ListObject | null
}

export interface EligibleExpenseFields {
  Category: number,
  ExpenseType: number,
  Provider: number,
  IsOtherProvider: boolean,
  OtherProviderName: string,
  ProviderPhoneNumber: string,
  AccountNumber: string,
  PaymentDueDate: number,
  Payment: number,
  PaymentPastDue: number,
  WhoPays: number
}