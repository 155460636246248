import { ListObject } from "src/app/shared/interfaces";
import { EligibleExpense, ExpenseObject, IncomeSource, Signature } from ".";

export interface Mortgage {
  IsOtherLender: boolean,
  OtherLenderName: string,
  IsOtherServicer: boolean,
  OtherServicerName: string,
  Servicer: ListObject | null,
  ServicerPhoneNumber: string,
  Lender: ListObject | null,
  LoanNumber: string,
  Balance: number,
  DelinquencyStatus: ListObject | null,
  Payment: number,
  PaymentDueDate: ListObject | null,
  InterestRate: number,
  LoanType: ListObject | null,
  LoanProgram: ListObject | null,
  AdjustmentDueDate: string,
  ForeclosureReceived: ListObject | null,
  ForeclosureReceivedDate: string | null,
  IsInForbearance: ListObject | null,
  ForbearanceEndDate: string,
  ForbearanceDetails: string,
  ForeclosureSaleScheduled: ListObject | null,
  ForeclosureSaleDate: string | null
}

export interface MortgageData {
  Mortgages: Mortgage[]
}

export interface FinancialData {
  IncomeSources_BorrowerMonthly: IncomeSource[],
  IncomeSources_CoBorrowerMonthly: IncomeSource[],
  IncomeSources_SpouseMonthly: IncomeSource[],
  Expenses_Other: ExpenseObject[],
  Bankruptcy_WasFiled: boolean,
  Bankruptcy_Type: ListObject | null,
  Bankruptcy_IsDischarged: ListObject | null,
  Bankruptcy_CaseNumber: string,
  Bankruptcy_FilingDate: string
}

export interface PrequalificationData {
  BorrowerFirstName: string,
  BorrowerLastName: string,
  Property_County: ListObject | null,
  Property_TotalNumberofPersonsLivingAtThisAddress: number
}

export interface SignatureData {
  BorrowerSignature: Signature | null,
  CoBorrowerSignature: Signature | null,
  SpouseSignature: Signature | null
}

export interface NotEligibleData {
  IsRequestGivenForEmailOfResults: boolean | null,
  IsPermissionGivenForContactByHUD: boolean | null
}

export interface PersonalData {
  CoBorrowerPrimaryPhoneBestTimeToCall: ListObject | null,
  CoBorrowerSecondaryPhoneBestTimeToCall: ListObject | null,
  CoBorrowerEmail: string,
  CoBorrowerPrimaryPhoneNumber: string,
  CoBorrowerPrimaryPhoneType: ListObject | null,
  CoBorrowerSecondaryPhoneNumber: string,
  CoBorrowerSecondaryPhoneType: ListObject | null,
  CoBorrowerDateOfBirth: string,
  CoBorrowerFirstName: string,
  CoBorrowerLastName: string,
  CoBorrowerMiddleName: string,
  CoBorrowerSSN: string | null,
  CoBorrowerAddress1: string,
  CoBorrowerAddress2: string,
  CoBorrowerCity: string,
  CoBorrowerCounty: ListObject | null,
  CoBorrowerState: ListObject | null,
  CoBorrowerZipCode: string,
  CoBorrowerIsVeteran: ListObject | null,
  BorrowerEmail: string,
  BorrowerPrimaryPhoneBestTimeToCall: ListObject | null,
  BorrowerSecondaryPhoneBestTimeToCall: ListObject | null,
  BorrowerPrimaryPhoneNumber: string,
  BorrowerPrimaryPhoneType: ListObject | null,
  BorrowerSecondaryPhoneNumber: string,
  BorrowerSecondaryPhoneType: ListObject | null,
  BorrowerDateOfBirth: string,
  BorrowerFirstName: string,
  IsThereACoBorrower: ListObject | null,
  IsCoBorrowerSpouse: ListObject | null,
  IsThereASpouse: ListObject | null,
  BorrowerLastName: string,
  MaritalStatus: ListObject | null,
  BorrowerMiddleName: string,
  BorrowerSSN: string | null,
  BorrowerAddress1: string,
  BorrowerAddress2: string,
  BorrowerCity: string,
  BorrowerCounty: ListObject | null,
  BorrowerState: ListObject | null,
  BorrowerZipCode: string,
  BorrowerIsVeteran: ListObject | null,
  HardshipReason: ListObject | null,
  HardshipStatement: string | null,
  SpouseFirstName: string,
  SpouseMiddleName: string,
  SpouseLastName: string,
  SpouseSSN: string | null,
  SpouseDateOfBirth: string,
  SpouseAddress1: string,
  SpouseAddress2: string,
  SpouseCity: string,
  SpouseCounty: ListObject | null,
  SpouseState: ListObject | null,
  SpouseZipCode: string,
  SpouseIsVeteran: ListObject | null,
  SpousePrimaryPhoneNumber: string,
  SpousePrimaryPhoneType: ListObject | null,
  SpousePrimaryPhoneBestTimeToCall: ListObject | null,
  SpouseEmail: string,
  SpouseSecondaryPhoneNumber: string,
  SpouseSecondaryPhoneType: ListObject | null,
  SpouseSecondaryPhoneBestTimeToCall: ListObject | null,
  HardshipOther: string,
  Property_Address1: string,
  Property_Address2: string,
  Property_City: string,
  Property_County: ListObject | null,
  Property_State: ListObject | null,
  Property_ZipCode: string,
  Property_TotalNumberofPersonsLivingAtThisAddress: number | null,
  Property_NumberOfDependentsAtAddress: number,
  Property_IsSameAsMailingAddress: boolean,
  BorrowerGender: ListObject | null,
  BorrowerRaces: ListObject[] | null,
  BorrowerEthnicity: ListObject | null,
  CoBorrowerGender: ListObject | null,
  CoBorrowerRaces: ListObject[] | null,
  CoBorrowerEthnicity: ListObject | null,
  SpouseGender: ListObject | null,
  SpouseRaces: ListObject[] | null,
  SpouseEthnicity: ListObject | null,
  IsSociallyDisadvantaged: ListObject | null,
  PreferredLanguage: ListObject | null,
  IsEmailAlertsEnabled: boolean
}
export interface EmploymentData {
  IsBorrowerEligibleForUnemployment: boolean,
  IsCoBorrowerEligibleForUnemployment: boolean,
  IsSpouseEligibleForUnemployemnt: boolean,
  BorrowerBenefitStartDate: string,
  BorrowerBenefitAmountMonthly: number,
  BorrowerBenefitProjectedEndDate: string,
  CoBorrowerBenefitStartDate: string,
  CoBorrowerBenefitAmountMonthly: number,
  CoBorrowerBenefitProjectedEndDate: string,
  SpouseBenefitStartDate: string,
  SpouseBenefitAmountMonthly: number,
  SpouseBenefitProjectedEndDate: string,
  IsBorrowerCurrentlyEmployed: ListObject | null,
  BorrowerEmployerName: string,
  BorrowerEmployerAddress: string,
  BorrowerEmployerCity: string,
  BorrowerEmployerState: ListObject | null,
  BorrowerEmployerCounty: ListObject | null,
  BorrowerEmployerZipCode: string,
  BorrowerEmployerPhone: string,
  BorrowerHowLongWithEmployer: ListObject | null,
  BorrowerSelfEmployedCompanyName: string,
  IsCoBorrowerCurrentlyEmployed: ListObject | null,
  CoBorrowerEmployerName: string,
  CoBorrowerEmployerAddress: string,
  CoBorrowerEmployerCity: string,
  CoBorrowerEmployerState: ListObject | null,
  CoBorrowerEmployerCounty: ListObject | null,
  CoBorrowerEmployerZipCode: string,
  CoBorrowerEmployerPhone: string,
  CoBorrowerHowLongWithEmployer: ListObject | null,
  CoBorrowerSelfEmployedCompanyName: string
}

export interface PropertyData {
  Property_LastKnownAppraisedValue: number,
  Property_IsIntentionToKeep: boolean,
  Property_IsIntentionToSell: boolean,
  Property_IsPrimaryResidence: boolean,
  Property_IsSecondaryResidence: boolean,
  Property_IsInvestment: boolean,
  Property_IsOwnerOccupied: boolean,
  Property_IsRenterOccupied: boolean,
  Property_IsVacant: boolean,
  Property_WhoPaysTaxes: ListObject | null,
  Property_AreTaxesCurrent: ListObject | null,
  Property_HasCondoOrHoaFees: ListObject | null,
  Property_CondoOrHoaFeesPaidTo: string,
  Property_CondoOrHoaFeesAmount: number,
  Property_WhoPaysHazardInsurance: ListObject | null,
  Property_HazardInsuranceIsCurrent: ListObject | null,
  Property_HazardInsuranceCompanyName: string,
  Property_HazardInsuranceCompanyPhone: string,
  Property_HasJudgements: boolean,
  Property_JudgementsDescription: string | null,
  EligibleExpenses: EligibleExpense[]
}